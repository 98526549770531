import React, { ReactNode } from "react"
import { Stack, Typography, Divider, IconButton, styled } from "@mui/material"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import CloseIcon from "@mui/icons-material/Close"
import { theme } from "@constants"

interface ICover {
  children: ReactNode
  title?: string | JSX.Element
  showFullscreenButton?: boolean
  handleClose?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const Cover: React.FC<ICover> = ({
  title,
  showFullscreenButton = true,
  handleClose,
  children,
  ...otherProps
}) => {
  return (
    <SCoverWrapper {...otherProps} className="SCoverWrapper">
      <SCoverHeader direction="row" alignItems="center" justifyContent="space-between">
        {typeof title === "string" ? <Typography variant="body1">{title}</Typography> : title}
        <Stack direction="row" alignItems="center">
          {showFullscreenButton && (
            <>
              <Divider flexItem orientation="vertical" />
              <SIconButton>
                <FullscreenIcon htmlColor={theme.palette.secondary.main} />
              </SIconButton>
            </>
          )}

          <Divider flexItem orientation="vertical" />

          <SIconButton onClick={handleClose}>
            <CloseIcon htmlColor={theme.palette.secondary.main} />
          </SIconButton>
        </Stack>
      </SCoverHeader>

      <SCoverBody>{children}</SCoverBody>
    </SCoverWrapper>
  )
}

const SCoverWrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.secondary.A700,
  width: "100%",
  height: "100%",
}))

const SCoverHeader = styled(Stack)({
  height: "33px",
  paddingLeft: "16px",
  borderBottom: `2px solid ${theme.palette.secondary.A300}`,
})

const SCoverBody = styled(Stack)({
  height: "100%",
  overflow: "hidden",
})

const SIconButton = styled(IconButton)({
  borderRadius: 0,
  height: "32px",
  width: "32px",
})
