import { EAll, IQuake, TTimeOptions } from "@types"
import { IParsedQuakes, IPieSeries } from "./_types"
import { getTimeEValue } from "@utils"

export class QuakeAdapter {
  private _quakes: IQuake[] = []

  parse(quakes: IQuake[], timeFilter: TTimeOptions[]): IParsedQuakes {
    this._quakes = this.filter(quakes, timeFilter)

    return {
      strongest: this.strongest,
      toPie: {
        total: this._quakes.length,
        series: this.toPie,
      },
    }
  }

  private get strongest(): IQuake | null {
    const sorted = [...this._quakes].sort((a, b) => b.magnitude - a.magnitude)
    return sorted[0] ?? null
  }

  private get toPie(): IPieSeries[] {
    const dest = { id: 0, value: 0 }
    const mid = { id: 1, value: 0 }
    const weak = { id: 2, value: 0 }

    this._quakes.forEach(({ magnitude }) => {
      if (magnitude >= 6) dest.value++
      else if (magnitude >= 4 && magnitude < 6) mid.value++
      else weak.value++
    })

    return [dest, mid, weak]
  }

  private filter(quakes: IQuake[], timeFilter: TTimeOptions[]): IQuake[] {
    const isValid = (quake: IQuake): boolean => {
      const timeVal = getTimeEValue(quake.time)
      return timeFilter.includes(timeVal) || timeFilter.includes(EAll.ALL)
    }
    const filtered = quakes.filter(isValid)

    return filtered
  }
}
