import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Stack, styled } from "@mui/material"

import { useDemoScriptContext, useLocalizationContext } from "@context"
import { BiggestQuake, QuakesPie, StatParams, DashboardTimeSelect } from "./fragments"
import { EAll, EMessageType, IAIData, IObserver, IQuake, TTimeOptions } from "@types"
import { QuakeAdapter } from "./_QuakeAdapter"
import { DetailsModal } from "./fragments/details_modal"
import { SSubtitle } from "./fragments/_styles"

export const Dashboard: React.FC = () => {
  const { l } = useLocalizationContext()

  const { demoScript, quakes, AIData } = useDemoScriptContext()

  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), [])

  const [parsedQuakes, setParsedQuakes] = useState(quakes)
  const [localAIData, setLocalAIData] = useState<IAIData>(AIData)

  const quakeAdapter = useMemo(() => new QuakeAdapter(), [])

  const [timeFilter, setTimeFilter] = useState<TTimeOptions[]>([EAll.ALL])
  const setTime = useCallback((time: TTimeOptions[]) => setTimeFilter(time), [])

  const { strongest, toPie } = useMemo(() => quakeAdapter.parse(parsedQuakes, timeFilter), [parsedQuakes, timeFilter])

  const observers = useMemo(
    () => [
      {
        type: EMessageType.QUAKE,
        cb: (data: Partial<IQuake>): void => {
          setParsedQuakes((prev) => updateQuakes(prev, data))
        },
      },
      {
        type: EMessageType.AI,
        cb: (data: Partial<IAIData>): void => {
          setLocalAIData((prev) => ({ ...prev, ...data }))
        },
      },
      {
        type: EMessageType.STOP,
        cb: (): void => {
          setParsedQuakes([...quakes])
          setLocalAIData(AIData)
        },
      },
    ],
    []
  )

  useEffect(() => {
    demoScript.on(observers as IObserver<EMessageType>[])

    return () => demoScript.off(observers as IObserver<EMessageType>[])
  }, [])

  return (
    <SContainer>
      <Stack gap="4px">
        <SSubtitle children={l.dashboard.stats.toUpperCase()} />
        <DashboardTimeSelect setTime={setTime} />
      </Stack>

      <QuakesPie {...toPie} />
      <BiggestQuake quake={strongest} l={l} />
      <StatParams {...localAIData} l={l} />

      <SButton variant="contained" onClick={toggleOpen}>
        {l.dashboard.details.details}
      </SButton>

      <DetailsModal AIData={localAIData} isOpen={isOpen} toggleOpen={toggleOpen} />
    </SContainer>
  )
}

function updateQuakes(oldQuakes: IQuake[], newQuake: Partial<IQuake>): IQuake[] {
  const quakes = [...oldQuakes]

  const ids = quakes.map((q) => q.id)

  if (ids.includes(newQuake.id!)) {
    const targetIndex = oldQuakes.findIndex((quake) => newQuake.id === quake.id)
    quakes[targetIndex] = { ...quakes[targetIndex], ...newQuake }
  } else {
    quakes.push(newQuake as IQuake)
  }

  return quakes
}

const SContainer = styled(Stack)(({ theme }) => ({
  maxWidth: "240px",
  width: "100%",
  maxHeight: "1000px",
  justifyContent: "space-between",
  gap: "10px",

  [theme.breakpoints.down("lg")]: {
    width: "210px",
  },
}))

const SButton = styled(Button)({
  width: "100%",
  heigth: "32px",
  fontSize: "12px",
})
